
let UserPost = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    let UserPostSession = "UserPostSession";
    /**
     * ��ȡ����ְλ
     * @param {any} success
     * @param {any} error
     */
    
    this.GetUserPost = function (orgId, success, error) {
      var url = ServiceBaseAddress + "/api/UserPost/Organization/" + orgId;
      TokenClient.Get(
        url,
        true,
        null,
        null,
        function (data) {
          if (success) {
            var jsonObj = JSON.parse(data);
            success(jsonObj);
          }
        },
        error
      );
    };
    /**
     * ��ȡҩ����ְλ����
     * @param {any} success
     * @param {any} error
     */
    this.DrugPosts = function (success, error) {
        var url = ServiceBaseAddress + '//Category/8';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 添加职位
     * @param {*} postName 
     * @param {*} categoryId 
     * @param {*} success 
     * @param {*} error 
     */
    this.addUserPost = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/UserPost';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 编辑职位
     * @param {*} param 
     * @param {*} success 
     * @param {*} error 
     */
    this.editUserPost = function (param, success, error) {
        var url = ServiceBaseAddress + '/api/UserPost';
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 删除职位
     * @param {*} postId 
     * @param {*} success 
     * @param {*} error 
     */
    this.removeUserPost = function (postId, success, error) {
        var url = ServiceBaseAddress + '/api/UserPost/' + postId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}

export { UserPost }
