let Department = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient
  let ServiceBaseAddress = serviceAddr
  let DepartmentSession = "DepartmentSession"

  this.Departments = function (success, error) {
    var url = ServiceBaseAddress + '/api/Department'
    TokenClient.Get(url, true, null, null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }
  this.HospitalDepartment = function (success, error) {
    var url = ServiceBaseAddress + '/api/Department/Category/Tree/8'
    TokenClient.Get(url, true, null, null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }
  this.OrgDepartment = function (orgId, success, error) {
    var url = ServiceBaseAddress + `/api/Department/Organization/Tree/${orgId}`
    TokenClient.Get(url, true, null, null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }
  this.AddDepartment = function (param, success, error) {
    var url = ServiceBaseAddress + '/api/Department/Organization'
    TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }
  this.Add = function (name, fullName, categoryId, parentId, success, error) {
    var url = ServiceBaseAddress + '/api/Department'
    var param = {
      name: name,
      fullName: fullName,
      categoryId: categoryId,
      parentId: parentId
    }
    TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }
  this.EditDepartment = function (param, success, error) {
    var url = ServiceBaseAddress + '/api/Department/Organization'
    TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }
  this.RemoveDepartment = function (departmentId, success, error) {
    var url = ServiceBaseAddress + '/api/Department/Organization/' + departmentId
    TokenClient.Delete(url, true, null, null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      }, error)
  }

  this.GetDepartment = function (organizationId, success, error) {
    var url = ServiceBaseAddress + "/api/Department/Organization/Tree/"
    if (organizationId) {
      url += organizationId
    } else {
      url += 0
    }
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.DepartmentUserNum = function (id, success, error) {
    var url = ServiceBaseAddress + `/api/Department/DepartmentUserNum/${id}`
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
  this.GetSessionDepartment = function () {
    var result = sessionStorage.getItem(DepartmentSession)
    if (result == null) {
      this.Departments(
        function (data) {
          sessionStorage.setItem(DepartmentSession, JSON.stringify(data.data))
          result = data.data
        },
        function (err) {
          console.log(err)
        }
      )
    } else {
      result = JSON.parse(result)
    }
    return result
  }

  /**
   * 导入部门
   * @param {*} item
   * @param {*} success
   * @param {*} error
   */
  this.ImportOrganizationData = function (path, success, error) {
    var url = ServiceBaseAddress + `/api/Department/ImportOrganizationData?filePath=${path}`
    TokenClient.Post(
      url,
      true,
      { "Content-Type": "application/json" },
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data)
          success(jsonObj)
        }
      },
      error
    )
  }
}

export { Department }
