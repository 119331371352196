let Versions = function(tokenClient, serviceAddr) {
    let TokenClient = tokenClient
    let ServiceBaseAddress = serviceAddr


    /**
     * 获取可升级版本
     * @param roleId
     * @param success
     * @param error
     * @constructor
     */
    this.UpgradeVersion= function (versionId, success, error) {
        var url = ServiceBaseAddress + '/api/VersionsPrice/UpgradeVersion/'+ versionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * 版本时长
     * @param versionId
     * @param success
     * @param error
     * @constructor
     */
    this.Duration = function (versionId, success, error) {
        var url = ServiceBaseAddress + '/api/VersionsPrice/'+ versionId;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
}
export { Versions }
