let UserTitle = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    let UserTitleSession = "UserTitleSession";
    /**
     * ��ȡ����ְ��
     * @param {any} success
     * @param {any} error
     */
    this.Titles = function (success, error) {
        var url = ServiceBaseAddress + '/api/UserTitle';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��ȡҩ����ְ������
     * @param {any} success
     * @param {any} error
     */
    this.DrugTitles = function (success, error) {
        var url = ServiceBaseAddress + '/api/UserTitle/Category/8/';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    this.TitlesByCategory = function (categoryId,success, error) {
        var url = ServiceBaseAddress + `/api/UserTitle/Category/${categoryId}`;
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ����ְ��
     * @param {any} titleName ְ������
     * @param {any} categoryId ְ�����ݷ���
     * @param {any} success
     * @param {any} error
     */
    this.Add = function (titleName, categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/UserTitle';
        var param = {
            userTitleName: titleName,
            categoryId: categoryId
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �޸�ְ����Ϣ
     * @param {any} titleId
     * @param {any} titleName ְ������
     * @param {any} categoryId ְ�����ݷ���
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (titleId, titleName, categoryId, success, error) {
        var url = ServiceBaseAddress + '/api/UserTitle';
        var param = {
            id: titleId,
            userTitleName: titleName,
            categoryId: categoryId
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ɾ��ְ��
     * @param {any} titleId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (titleId, success, error) {
        var url = ServiceBaseAddress + '/api/UserTitle/' + titleId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }

    /**
     * haha
     */
    this.GetSessionUserTitle = function () {
        var result = sessionStorage.getItem(UserTitleSession);
        if (result == null) {
            this.Titles(function (data) {
                    /*�����û�ְ�Ƶ�����*/
                    sessionStorage.setItem(
                        UserTitleSession,
                        JSON.stringify(data.data)
                    );
                    result = data.data;
                },
                function (err) {
                    console.log(err);
                });
        } else {
            result = JSON.parse(result);
        }
        return result;
    }
}

export { UserTitle }
