
let Role = function (tokenClient, serviceAddr) {
    let TokenClient = tokenClient;
    let ServiceBaseAddress = serviceAddr;
    /**
     * 查询菜单分组
     */
    this.AllManagerMenus = function (success, error) {
        var url = ServiceBaseAddress + '/api/User/Menus'
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            }, error)
    }
    /**
     * ��ѯ���н�ɫ
     * @param {any} success
     * @param {any} error
     */
    this.Roles = function (success, error) {
        var url = ServiceBaseAddress + '/api/Role';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
    * ��ȡҩ���Ľ�ɫ����
    * @param {any} success
    * @param {any} error
    */
    // this.DrugRoles = function (success, error) {
    //     var url = ServiceBaseAddress + '/api/Role/Category/8';
    //     TokenClient.Get(url, true, null, null,
    //         function (data) {
    //             if (success) {
    //                 var jsonObj = JSON.parse(data);
    //                 success(jsonObj);
    //             }
    //         }, error);
    // }
    /**
     * ������ɫ
     * @param {any} categoryId ��ɫ���ݷ��࣬1��ϵͳ 2��ҽԺ
     * @param {any} roleKey ��ɫ��־
     * @param {any} roleName ��ɫ����
     * @param {any} roleDescript ��ɫ����
     * @param {any} success
     * @param {any} error
     */
    this.Add = function (productId, roleKey, roleName, alias, roleDescript, roleType,success, error) {
        var url = ServiceBaseAddress + '/api/Role';
        var param = {
            roleKey: roleKey,
            roleName: roleName,
            alias: alias,
            roleDescription: roleDescript,
            productId: productId,
            roleStatu: 1,
            roleType: roleType
        };
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                console.log(data)
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * �޸Ľ�ɫ��Ϣ
     * @param {any} roleId
     * @param {any} categoryId ��ɫ���ݷ��࣬1��ϵͳ 2��ҽԺ
     * @param {any} roleKey ��ɫ��־
     * @param {any} roleName ��ɫ����
     * @param {any} roleDescript ��ɫ����
     * @param {any} roleStatu ��ɫ״̬��1������ -1������
     * @param {any} success
     * @param {any} error
     */
    this.Edit = function (roleId, productId, roleKey, roleName, alias, roleDescript, roleStatu, roleType,success, error) {
        var url = ServiceBaseAddress + '/api/Role';
        var param = {
            id: roleId,
            roleKey: roleKey,
            roleName: roleName,
            alias:alias,
            roleDescription: roleDescript,
            productId: productId,
            roleStatu: roleStatu,
            roleType: roleType
        };
        TokenClient.Put(url, true, { 'Content-Type': 'application/json' }, param,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ɾ����ɫ
     * @param {any} roleId
     * @param {any} success
     * @param {any} error
     */
    this.Remove = function (roleId, success, error) {
        var url = ServiceBaseAddress + '/api/Role/' + roleId;
        TokenClient.Delete(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��ȡ��ɫ�Ŀ�����ϵͳ
     * @param {any} roleId
     * @param {any} success
     * @param {any} error
     */
    this.Subsystems = function (roleId, success, error) {
        var url = ServiceBaseAddress + '/api/Role/' + roleId + '/Subsystem';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ���ý�ɫ����ϵͳ
     * @param {any} roleId
     * @param {any} subsystemIds ��ϵͳ��id����,[1,2,3,4]
     * @param {any} success
     * @param {any} error
     */
    this.SetSubsystem = function (roleId, subsystemIds, success, error) {
        var url = ServiceBaseAddress + '/api/Role/' + roleId + '/Subsystem';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, subsystemIds,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ��ȡ��ɫ�Ŀ���ģ��
     * @param {any} roleId
     * @param {any} success
     * @param {any} error
     */
    this.Models = function (roleId, success, error) {
        var url = ServiceBaseAddress + '/api/Role/' + roleId + '/Model';
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * ���ý�ɫ�Ŀ���ģ��
     * @param {any} roleId
     * @param {any} modelIds ����ģ���id����,[1,2,3,4]
     * @param {any} success
     * @param {any} error
     */
    this.SetModel = function (roleId, modelIds, success, error) {
        var url = ServiceBaseAddress + '/api/Role/' + roleId + '/Model';
        TokenClient.Post(url, true, { 'Content-Type': 'application/json' }, modelIds,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
     * 机构下的角色列表
     * @param {any} success
     * @param {any} error
     */
     this.CateRoles = function (keyWord,showSuper ,isAll , success, error) {
        var url = ServiceBaseAddress + '/api/Role/Organization';
        if (keyWord) {
            url += `/${keyWord}/${showSuper}/${isAll}`;
        }
        else {
            url += `/%20/${showSuper}/${isAll}`;
        }
        TokenClient.Get(url, true, null, null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data);
                    success(jsonObj);
                }
            }, error);
    }
    /**
   * 查询指定产品下角色
   * @param {any} success
   * @param {any} error
   */
    this.GetRoleByProductId = function (id, keyWord, success, error) {
        var url = ServiceBaseAddress + "/api/Role/Product/" + id
        if (keyWord) {
            url += `/${keyWord}`
        } else {
            url += "/%20"
        }
        TokenClient.Get(
            url,
            true,
            null,
            null,
            function (data) {
                if (success) {
                    var jsonObj = JSON.parse(data)
                    success(jsonObj)
                }
            },
            error
        )
    }
}

export { Role };
